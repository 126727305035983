<template>
  <div class="result-close">
    <div class="content">
      <img width="400" :src="$isZh() ? ($isDark() ? require('@/assets/img/noCaptch.png') : require('@/assets/img/noCaptch-light.png')) : ($isDark() ? require('@/assets/img/noCaptch-en.png') : require('@/assets/img/noCaptch-light-en.png'))" />
      <p>
        {{ $t('other.components.report.captchText1') }}<span
          class="login-btn"
          @click="login"
          >{{ $t('other.components.report.captchText2') }}</span
        >！
      </p>
      <el-button v-if="showFooter" @click="showCaptch" type="default" class="go-home-btn">
        <svg-icon name="yanzheng" />
        <span class="captch-text">{{ $t('other.components.report.captchBtn') }}</span>
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import emitter from 'app/emitter'

const emit = defineEmits(['showCaptch'])

defineProps({
  showFooter: {
    type: Boolean,
    default: false
  }
})

const showCaptch = () => {
  emit('showCaptch')
}
const login = () => {
  emitter.emit('showLogin')
}
</script>

<style lang="scss" scoped>
.result-close {
  background-color: var(--color-bg-2);
  /* max-width: 1680px; */
  margin: auto;
  min-height: 600px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // top: 0;
  width: 100%;
  z-index: 2000;
  .content {
    height: 490px;
    // background: red;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #969799;
    & > p {
      line-height: 45px;
    }
    .login-btn {
      color: #00AB7A;
      cursor: pointer;
    }
    .go-home-btn {
      text-align: left;
      color: #969799;
      padding-left: 0;
      width: 400px;
      height: 40px;
      background: var(--color-bg-3);
      border: 1px solid #5a5e6d4d;
      border-radius: 2px;
      color: var(--color-text-3);
      justify-content: flex-start;
      svg {
        // width: 32px;
        margin: 0 8px;
        text-align: center;
        font-size: 14px;
      }
      .captch-text {
        border-left: 1px solid var(--color-text-3);
        padding-left: 8px;
      }
    }
  }
}
</style>
