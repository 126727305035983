import request from 'app/http'
import qs from 'qs'

const getTaskPublicList = (params) => {
  return request.get('/task/public/list', {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getTaskSelected = (params) => {
  return request.get('/task/get/featured', params)
}

const getTaskFilters = (params) => {
  return request.get('/task/search/filter', {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getGlobalTaskFilters = () => {
  return request.get('/task/get/hunt/filter')
}

const getGlobalTasks = (params) => {
  // return request.get('/task/get/hunt/list', {
  //   params,
  //   paramsSerializer: params => {
  //     return qs.stringify(params, {
  //       indices: false
  //     })
  //   }
  // })
  return request.post('/task/get/hunt/list', params)
}

export {
  getTaskPublicList,
  getTaskFilters,
  getTaskSelected,
  getGlobalTasks,
  getGlobalTaskFilters
}
