<template>
  <div class="switch-tab-wrap">
    <!-- <div
      v-for="item in list"
      :key="item.value"
      :class="['switch-tab-item', {active: val === item.value}]"
      @click="$emit('change', item.value)"
    >{{item.label}}</div> -->
    <el-button-group>
      <el-button v-for="item in list" :key="item.value" :class="['switch-tab-item', {active: val === item.value}]" @click="$emit('change', item.value)">{{item.label}}</el-button>
    </el-button-group>
  </div>
</template>

<script>
export default {
  name: 'SwitchTab',
  props: {
    list: {
      type: Array,
      default () {
        return [
          {
            label: '选项 1',
            value: '1'
          },
          {
            label: '选项 2',
            value: '2'
          },
          {
            label: '选项 3',
            value: '3'
          }
        ]
      }
    },
    val: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .switch-tab-wrap {
    width: fit-content;
    display: flex;
    font-size: 14px;
    border-radius: 2px;
    color: var(--color-text-2);
    .switch-tab-item {
      width: 88px;
      text-align: center;
      line-height: 32px;
      padding: 0 13px;
      color: var(--color-text-2);
      cursor: pointer;
      background: var(--detect-config-bg);
      // border-right: 1px solid var(--color-border-1);
      &:nth-child(3) {
        // border-right: none;
      }
      &.active {
        position: relative;
        background: #009e71;
        color: #fff;
        border-right: none;
      }
    }
  }
</style>
